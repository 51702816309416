




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/AuthController'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    TextInput,
    LoadingOverlay,
  },
})
export default class ResetPasswordModal extends Vue {
  @Prop({ type: String, required: true }) token!: string

  form = {
    password: '',
    passwordConfirmation: '',
  }

  created(): void {
    controller.forgotPasswordVerify(this.token)
  }

  @Validations()
  validations(): Record<string, any> {
    return {
      form: {
        password: {
          required,
          maxLength: maxLength(72),
          minLength: minLength(8),
        },
        passwordConfirmation: {
          sameAs: sameAs(() => this.form.password),
        },
      },
    }
  }

  private sendResetPassword() {
    this.$emit(
      'resetPassword',
      this.form.password,
      this.form.passwordConfirmation
    )
  }

  get isLoading(): boolean {
    return controller.isLoadingForgotPassword || controller.isLoading
  }

  private revalidatePasswordConfirm() {
    if (this.$v?.form.passwordConfirmation?.$dirty) {
      this.$v?.form.passwordConfirmation?.$touch()
    }
  }
}
