import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { ForgotPasswordRequest, ForgotPasswordVerifyRequest, LoginApiRequest, ResetPasswordRequest } from '@/data/payload/api/AuthRequest'
import store from '@/app/ui/store'
import { AuthPresenter } from '../presenters/AuthPresenter'
import { Account, Token } from '@/domain/entities/Account'
import { EventBus, EventBusConstants, LocalStorage } from '@/app/infrastructures/misc'
import router from '@/app/ui/router'
import { RawLocation } from 'vue-router'

export interface LoginState {
  isLoading: boolean
  isLoadingProfile: boolean
  isLoadingForgotPassword: boolean
}

@Module({ namespaced: true, store, name: 'login', dynamic: true })
class AuthController extends VuexModule implements LoginState {
  private presenter: AuthPresenter = container.resolve(AuthPresenter)
  public isLoading = false
  public isLoadingProfile = false
  public isLoadingForgotPassword = false

  @Action({ rawError: true })
  public getMe() {
    this.setLoadingProfile(true)

    this.presenter
      .getMe()
      .then(res => {
        this.setMyProfileData(res)
        router.replace(
          (router.currentRoute.query.redirect as RawLocation) || '/'
        )
      })
      .catch(error => {
        Vue.notify({
          title: 'Login Failed',
          text: 'Please Try Again',
          type: 'warn',
          duration: 3000,
        })
        console.error('Failed fetching', error)
      })
      .finally(() => {
        this.setLoadingProfile(false)
      })
  }

  @Action({ rawError: true })
  public sendLogin(params: { email: string; password: string }) {
    this.setLoading(true)

    this.presenter
      .onLogin(new LoginApiRequest('CMS', params.email, params.password))
      .then(res => {
        this.setAuthData({ auth: res, email: params.email })
        this.getMe()
      })
      .catch(error => {
        Vue.notify({
          title: 'Login Failed',
          text:
            error.status === 400 ? error.error.message.en : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public forgotPassword(params: { email: string }) {
    this.setLoading(true)

    this.presenter
      .forgotPassword(new ForgotPasswordRequest(params.email))
      .then(() => {
        EventBus.$emit(EventBusConstants.FORGOT_PASSWORD_SUCCESS, {
          email: params.email,
        })
      })
      .catch(error => {
        if (error.status === 403) {
          EventBus.$emit(EventBusConstants.FORGOT_PASSWORD_LIMIT)

          return
        }

        Vue.notify({
          title: 'Forgot Password',
          text: error.error.message.en,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public forgotPasswordVerify(token: string) {
    this.setLoadingForgotPassword(true)

    this.presenter
      .forgotPasswordVerify(new ForgotPasswordVerifyRequest(token))
      .catch(error => {
        router.replace({
          name: 'ResetPassword',
          query: {
            status: 'invalid',
            message: error.error.message.en
          }
        })
      })
      .finally(() => {
        this.setLoadingForgotPassword(false)
      })
  }

  @Action({ rawError: true })
  public resetPassword(form: { token: string, password: string, passwordConfirmation: string }) {
    this.setLoading(true)

    this.presenter
      .resetPassword(new ResetPasswordRequest(form.token, form.password, form.passwordConfirmation))
      .then(() => {
        EventBus.$emit(EventBusConstants.RESET_PASSWORD_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Reset Password',
          text: error.error.message.en,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setAuthData(data: { auth: Token; email: string }) {
    LocalStorage.setLocalStorage(LocalStorage.TOKEN_KEY, data.auth.accessToken)
    LocalStorage.setLocalStorage(
      LocalStorage.TOKEN_EXPIRED,
      data.auth.expiredAt
    )
    LocalStorage.setLocalStorage(LocalStorage.LOGIN_IDENTITY, data.email, true)
  }

  @Mutation
  private setMyProfileData(data: Account) {
    LocalStorage.setLocalStorage(LocalStorage.MY_ROLE, data.role, true)
    LocalStorage.setLocalStorage(LocalStorage.ACCOUNT_ID, data.id, true)
    LocalStorage.setLocalStorage(
      LocalStorage.ACCESS_MENU,
      data.accessMenus?.map(item => item.slug),
      true
    )
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingForgotPassword(bool: boolean) {
    this.isLoadingForgotPassword = bool
  }

  @Mutation
  private setLoadingProfile(bool: boolean) {
    this.isLoadingProfile = bool
  }
}

export default getModule(AuthController)
