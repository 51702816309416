


















import { Component, Prop, Vue } from 'vue-property-decorator'
import IconEmail from '@/app/ui/assets/icon_email.vue'

@Component({
  inheritAttrs: false,
  components: {
    IconEmail,
  },
})
export default class ForgotPasswordInput extends Vue {
  @Prop({ type: String }) private value!: string
  @Prop({ type: Boolean }) private error!: boolean

  private onInput(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('input', target.value)
  }
}
