






































































































































































import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/AuthController'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LionParcelLogo from '@/app/ui/assets/lion_parcel_logo.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ForgotPasswordInput from './components/ForgotPasswordInput/index.vue'
import ResetPasswordModal from './components/ResetPasswordModal/index.vue'
import ResetPasswordSuccess from '@/app/ui/assets/reset_password_success.vue'
import ForgotPasswordExpired from '@/app/ui/assets/forgot_password_expired.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import { email, required } from 'vuelidate/lib/validators'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    TextInput,
    Button,
    LionParcelLogo,
    Modal,
    ForgotPasswordInput,
    ResetPasswordModal,
    ResetPasswordSuccess,
    ForgotPasswordExpired,
  },
})
export default class LoginPage extends Vue {
  controller = controller
  form = {
    email: '',
    password: '',
  }

  forgotPasswordModal = false
  forgotPasswordEmail = ''
  resetPasswordModal = false
  resetPasswordSuccess = false
  forgotPasswordLimit = false

  created(): void {
    if (
      this.$route.query.forgot_password ||
      this.$route.query['forgot-password']
    ) {
      this.forgotPasswordModal = true
    }

    if (
      this.$route.query.reset_password ||
      this.$route.query['reset-password']
    ) {
      this.resetPasswordModal = true
    }

    EventBus.$on(EventBusConstants.FORGOT_PASSWORD_SUCCESS, () => {
      this.forgotPasswordModal = false

      this.$router.push({
        name: 'ResetPassword',
        query: {
          status: 'send',
          email: this.forgotPasswordEmail,
        },
      })
    })

    EventBus.$on(EventBusConstants.RESET_PASSWORD_SUCCESS, () => {
      this.resetPasswordModal = false
      this.resetPasswordSuccess = true
    })

    EventBus.$on(EventBusConstants.FORGOT_PASSWORD_LIMIT, () => {
      this.forgotPasswordModal = false
      this.forgotPasswordEmail = ''
      this.forgotPasswordLimit = true
    })
  }

  @Validations()
  validations(): Record<string, any> {
    return {
      forgotPasswordEmail: {
        required,
        email,
      },
    }
  }

  get loginButtonText(): string {
    if (!this.resetPasswordModal && !this.forgotPasswordModal) {
      if (this.controller.isLoading) {
        return 'Loading...'
      }

      if (this.controller.isLoadingProfile && !this.resetPasswordModal) {
        return 'Fetching Profile...'
      }
    }

    return 'Login'
  }

  private submitLogin() {
    controller.sendLogin(this.form)
  }

  private sendForgotPassword() {
    controller.forgotPassword({
      email: this.forgotPasswordEmail,
    })
  }

  private resetPassword(password: string, passwordConfirmation: string) {
    controller.resetPassword({
      token: (this.$route.query.reset_password ||
        this.$route.query['reset-password']) as string,
      password,
      passwordConfirmation,
    })
  }

  get disabled(): boolean {
    return (
      (this.controller.isLoading || this.controller.isLoadingProfile) &&
      !this.resetPasswordModal &&
      !this.forgotPasswordModal
    )
  }

  private closeResetPasswordSuccessModal() {
    this.resetPasswordSuccess = false
    this.$router.push({
      name: 'LoginPage',
    })
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.FORGOT_PASSWORD_SUCCESS)
    EventBus.$off(EventBusConstants.RESET_PASSWORD_SUCCESS)
    EventBus.$off(EventBusConstants.FORGOT_PASSWORD_LIMIT)
  }
}
